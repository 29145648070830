<template>
  <BaseModal
    :value.sync="opened"
    scrollable
    title="Title from props"
    :hide-actions="false"
  >
    <ImagesBrowser
      v-if="loaded"
      :pictures="pictures"
      :pictures-to-import="picturesToImport"
      :load-more-available="true"
      @pictureClick="handlePictureClick"
    />
    <template v-slot:actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <BaseButton outlined color="gray" @click="importPictures">
          Discard
        </BaseButton>

        <BaseButton @click="loadMore">Load more</BaseButton>
      </v-card-actions>
    </template>
  </BaseModal>
</template>

<script>
import ImagesBrowser from "@/components/Forms/Images/ImagesBrowser";
import { mdiCheck } from "@mdi/js";

export default {
  name: "ImagesInstagramForm",
  components: { ImagesBrowser },
  props: {
    token: {
      type: String,
      required: true
    },
    active: {
      type: Boolean
    }
  },
  data: () => ({
    opened: false,
    pictures: [],
    picturesToImport: [],
    paging: {},
    loaded: false,
    svgCheck: mdiCheck
  }),
  watch: {
    active(val) {
      this.opened = !!val;
    },

    opened(val) {
      if (!val) {
        this.$emit("closeModal", "instagram");
      }
    }
  },
  mounted() {
    if (this.token) {
      this.getPictures();
    }
  },
  methods: {
    loadMore() {
      fetch(this.paging.next)
        .then(r => r.json())
        .then(r => {
          this.pictures = [...this.pictures, ...r.data];
          this.paging = r.paging;
        });
    },
    handlePictureClick(src) {
      if (this.picturesToImport.includes(src)) {
        this.picturesToImport.splice(this.picturesToImport.indexOf(src), 1);
      } else {
        this.picturesToImport.push(src);
      }
    },
    isChecked(src) {
      return this.picturesToImport.includes(src);
    },
    getPictures() {
      fetch(
        `${process.env.VUE_APP_INSTAGRAM_GRAPH_API_URL}/me/media?fields=id,username,media_url&access_token=${this.token}&limit=6`
      )
        .then(r => {
          return r.json();
        })
        .then(r => {
          this.pictures = r.data;
          this.paging = r.paging;
          this.loaded = true;
        });
    },
    importPictures() {
      this.$emit("picturesImport", this.picturesToImport);
    }
  }
};
</script>
